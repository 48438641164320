import React from 'react'
import styled, { withTheme } from 'styled-components'

import { COLORS } from 'COLORS'

const Wrapper = styled.svg`
  position: absolute;
  right: -5px;
  bottom: 4px;
  margin-left: 5px;
`

const UnlockNavIcon = ({ theme }) => {
  return (
    <Wrapper width="20" height="19" viewBox="0 0 20 19">
      <g fill={COLORS.grey400} fillRule="evenodd">
        <path
          stroke="#232323"
          strokeWidth="4"
          d="M16 10V8l-.007-.117C15.936 7.386 15.513 7 15 7l-.117.007c-.497.057-.883.48-.883.993v2h2zm-4-2l.005-.176C12.096 6.249 13.402 5 15 5c1.657 0 3 1.343 3 3v2h1c.552 0 1 .448 1 1v7c0 .552-.448 1-1 1h-8c-.552 0-1-.448-1-1v-7c0-.552.448-1 1-1h1V8z"
        />
        <path
          fill={theme.colors.primarySidenav}
          d="M16 10V8l-.007-.117C15.936 7.386 15.513 7 15 7l-.117.007c-.497.057-.883.48-.883.993v2h2zm-4-2l.005-.176C12.096 6.249 13.402 5 15 5c1.657 0 3 1.343 3 3v2h1c.552 0 1 .448 1 1v7c0 .552-.448 1-1 1h-8c-.552 0-1-.448-1-1v-7c0-.552.448-1 1-1h1V8z"
        />
      </g>
    </Wrapper>
  )
}

const wrappedComponent = withTheme(UnlockNavIcon)
export { wrappedComponent as UnlockNavIcon }
