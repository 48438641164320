import { TRANSITION_TIMING } from 'constants/sidebar'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { COLORS } from 'COLORS'

const Wrapper = styled.svg`
  position: absolute;
  opacity: ${props => (props.collapsed ? 0 : 1)};
  transition: opacity 0.3s ${TRANSITION_TIMING};
  bottom: 2px;
  right: -9px;
  padding-left: 5px;
`

const LockNavIcon = ({ collapsed }) => {
  return (
    <Wrapper collapsed={collapsed} width="24" height="21" viewBox="0 0 24 21">
      <g fill={COLORS.grey400} fillRule="evenodd">
        <path
          id="unlocked"
          stroke="#232323"
          strokeWidth="2"
          d="M19 9V7v2h4.004l-.01-2.205c-.053-1.065-.523-2.02-1.25-2.706C21.027 3.414 20.062 3 19 3c-1.105 0-2.105.448-2.828 1.172C15.448 4.895 15 5.895 15 7v2h-4c-.552 0-1.052.224-1.414.586C9.224 9.948 9 10.448 9 11v7c0 .552.224 1.052.586 1.414.362.362.862.586 1.414.586h8c.552 0 1.052-.224 1.414-.586.362-.362.586-.862.586-1.414v-7c0-.552-.224-1.052-.586-1.414C20.052 9.224 19.552 9 19 9h0z"
        />
      </g>
    </Wrapper>
  )
}

LockNavIcon.propTypes = {
  collapsed: PropTypes.bool.isRequired
}

export { LockNavIcon }
